import React, {useEffect, useState} from "react";

export const EssentialsPreview = ({targetDate}) => {

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const newOpacity = Math.max(0, 0 + (scrollTop-200) / 150); // Adjust 500 for sensitivity
      setOpacity(newOpacity);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return ([
    <div className="cardInfo" style={{opacity: opacity}}>
      <div className="cardRow">
        <div className="cardSection"></div>
        <div className="cardSection up"><div className="smallTitle">В ВАШИХ РУКАХ ЧАСТЬ</div><div className="bigTitle">БОЛЬШОГО<br/>ПРОЕКТА</div></div>
      </div>
      <div className="cardRow">
        <div className="cardSection down"><div className="time leftmargin">0{timeLeft.days} <div className="smallestTitle">д</div></div><div className="time leftmargin">{timeLeft.hours < 10 ? 0 : ""}{timeLeft.hours}  <div className="smallestTitle">ч</div>{timeLeft.minutes < 10 ? 0 : ""}{timeLeft.minutes}  <div className="smallestTitle">м</div></div></div>
        <div className="cardSection down"><div className="time smallleftmargin">{timeLeft.seconds < 10 ? 0 : ""}{timeLeft.seconds}  <div className="smallestTitle">с</div></div></div>
      </div>
    </div>, <a href="https://t.me/iosifianart" className="buttonToAction"><div className="buttonToAction-wrapper">больше, чем визитка</div></a> 
    // <div className="contact"><a href="https://t.me/alexanderbtw">Заказать сайт</a></div>
  ]
  );
};

const calculateTimeLeft = (targetDate) => {
  const difference = new Date(targetDate).getTime() - new Date().getTime();
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};



export default EssentialsPreview;
