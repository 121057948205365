import React, { useEffect } from "react";
import { useState } from "react";
import "../styles/pages/main.scss";

import BlackLogo from "../icons/BlackLogo";
import WhiteLogo from "../icons/WhiteLogo";
import downArrowBlack from "../icons/downArrowBlack";
import downArrowWhite from "../icons/downArrowWhite";

import EssentialsPreview from "../components/EssentialsPreview";

export default function Main({theme}) {
    // const [Logo, setLogo] = useState(theme === 'dark' ? BlackLogo : WhiteLogo)
    // const [Arrow, setArrow] = useState(theme === 'dark' ? downArrowWhite : downArrowBlack)

    // useEffect(()=>{
    //     setArrow(theme === 'dark' ? downArrowWhite : downArrowBlack)
    //     setLogo(theme === 'dark' ? WhiteLogo : BlackLogo);
    // }, 
    // [theme])
    const [opacity, setOpacity] = useState(1);


    useEffect(() => {
        const handleScroll = () => {
          const scrollTop = window.pageYOffset;
          const newOpacity = Math.max(0, 1 - scrollTop / 300); // Adjust 500 for sensitivity
          setOpacity(newOpacity);
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
      const targetDate = '2024-12-12T12:00:00'
    

    return (
        <div className="mainView">
            <div className="faceView">
                <img style={{opacity: opacity}} src="card.gif" className="cardGif"/>
            </div>
            <EssentialsPreview targetDate={targetDate}/>
        </div>
    )
}